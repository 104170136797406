import type { Status } from '@/types/Types'

export type Rule = (value: string | any[], t: (key: string) => string) => Status

export function length(
  { min, max }: { min: number; max: number },
  t: (key: string) => string,
  isNumber?: boolean
): Rule {
  return function (value: string | any[]): Status {
    if (Array.isArray(value)) {
      if (max === Infinity) {
        const result = Boolean(value.length >= min)

        return {
          valid: result,
          message: result
            ? undefined
            : `${t('global.form.validation.min-options-1')}
            ${min}
            ${t('global.form.validation.min-options-2')}.`,
          touched: true
        }
      }

      const result = Boolean(value.length >= min && value.length <= max)

      return {
        valid: result,
        message: result
          ? undefined
          : `${t('global.form.validation.min-options-1')}
          ${min}
          ${t('global.form.validation.and')}
          ${max}
          ${t('global.form.validation.min-max-options-2')}.`,
        touched: true
      }
    }

    if (isNumber) {
      const convertedValue = parseFloat(value)
      if (max === Infinity) {
        const result = Boolean(convertedValue >= min)

        return {
          valid: result,
          message: result ? undefined : `${t('global.form.validation.over-equal')}${min}.`,
          touched: true
        }
      }

      if (min === -Infinity) {
        const result = Boolean(convertedValue <= max)

        return {
          valid: result,
          message: result ? undefined : `${t('global.form.validation.under-equal')}${max}.`,
          touched: true
        }
      }

      const result = Boolean(convertedValue >= min && convertedValue <= max)

      return {
        valid: result,
        message: result
          ? undefined
          : `${t('global.form.validation.min-max-field')}
          ${min}
          ${t('global.form.validation.and')}
          ${max}.`,
        touched: true
      }
    }

    if (max === Infinity) {
      const result = Boolean(value.length >= min)

      return {
        valid: result,
        message: result
          ? undefined
          : `${t('global.form.validation.at-least')}
          ${min}
          ${t('global.form.validation.characters')}.`,
        touched: true
      }
    }

    if (min === -Infinity) {
      const result = Boolean(value.length <= max)

      return {
        valid: result,
        message: result
          ? undefined
          : `${t('global.form.validation.at-max')}
          ${max}
          ${t('global.form.validation.characters')}.`,
        touched: true
      }
    }

    const result = Boolean(value.length >= min && value.length <= max)

    return {
      valid: result,
      message: result
        ? undefined
        : `${t('global.form.validation.min-max-field')}
        ${min}
        ${t('global.form.validation.and')}
        ${max}
        ${t('global.form.validation.characters')}.`,
      touched: true
    }
  }
}

export function validateCount(
  value: any[],
  { min, max }: { min: number; max: number },
  t: (key: string) => string
): Status {
  const result = Boolean(value.length >= min && value.length <= max)
  return {
    valid: result,
    message: result
      ? undefined
      : `${t('global.form.validation.min-options-1')}
      ${min}
      ${t('global.form.validation.and')}
      ${max}
      ${t('global.form.validation.min-max-options-2')}.`,
    touched: true
  }
}

export function validateOneSelected(value: any[], t: (key: string) => string): Status {
  const result = Boolean(value.length > 0)
  return {
    valid: result,
    message: result
      ? undefined
      : `${t('global.form.validation.min-options-1')}
      ${t('global.form.validation.one-option')}.`,
    touched: true
  }
}

export const required: Rule = (value: string | any[], t: (key: string) => string): Status => {
  const result = Boolean(value)
  return {
    valid: result,
    message: result ? undefined : `${t('global.form.validation.required')}.`,
    touched: true
  }
}

export function validate(value: string | any[], rules: Rule[], t: (key: string) => string): Status {
  for (const rule of rules) {
    const result = rule(value, t)
    if (!result.valid) {
      return result
    }
  }

  return {
    valid: true,
    message: '',
    touched: true
  }
}

export const noSpaces: Rule = (value: string | any[], t: (key: string) => string): Status => {
  const result = Boolean(!value.includes(' '))
  return {
    valid: result,
    message: result ? undefined : `${t('global.form.validation.no-spaces')}.`,
    touched: true
  }
}

export function validateSelectAmount(
  { min, max }: { min: number; max: number },
  t: (key: string) => string
) {
  return function (value: string): Status {
    const result = Boolean(parseInt(value) >= min && parseInt(value) <= max)

    return {
      valid: result,
      message: result
        ? undefined
        : `${t('global.form.validation.min-options-1')}
        ${min}
        ${t('global.form.validation.and')}
        ${max}
        ${t('global.form.validation.min-max-options-2')}.`,
      touched: true
    }
  }
}

export function regex(
  { regex, message }: { regex: RegExp; message: string },
  t: (key: string) => string
): Rule {
  return function (value: string | any[]): Status {
    if (Array.isArray(value)) {
      const result = Boolean(value.every((item) => item.match(regex)))
      return {
        valid: result,
        message: result ? undefined : t(message),
        touched: true
      }
    }
    const result = Boolean(value.match(regex))
    return {
      valid: result,
      message: result ? undefined : t(message),
      touched: true
    }
  }
}

export function compareValue(
  value: string,
  compare: string,
  whatCompare: string,
  t: (key: string) => string
): Rule {
  const result = Boolean(value === compare)

  return function (): Status {
    return {
      valid: result,
      message: result
        ? undefined
        : `${t('global.form.validation.the')}
        ${whatCompare}
        ${t('global.form.validation.not-identical')}.`,
      touched: true
    }
  }
}
