<template>
  <div class="rules-container">
    <img
      src="/avatars/rules/avatars-high-five.svg"
      alt="Avatars high five"
      class="avatars"
      v-if="!inModal"
    />
    <div class="content" :class="{ 'no-background': inModal }">
      <h2 class="primary">{{ $t('pages.rules.title') }}</h2>
      <p>{{ $t('pages.rules.text-1') }}</p>
      <p v-if="locale === 'fr'">{{ $t('pages.rules.text-2') }}</p>
      <ol>
        <li>{{ $t('pages.rules.rule-1') }}</li>
        <li>{{ $t('pages.rules.rule-2') }}</li>
        <li>{{ $t('pages.rules.rule-3') }}</li>
        <li>{{ $t('pages.rules.rule-4') }}</li>
        <li>{{ $t('pages.rules.rule-5') }}</li>
        <li>{{ $t('pages.rules.rule-6') }}</li>
        <li>{{ $t('pages.rules.rule-7') }}</li>
        <li>{{ $t('pages.rules.rule-8') }}</li>
        <li>
          {{ $t('pages.rules.rule-9-a') }}
          <a
            :href="locale === 'fr' ? HOW_TO_INSTALL_LINK_FR : HOW_TO_INSTALL_LINK_EN"
            aria-label="How to install"
            class="regular-link"
            target="_blank"
            >{{ $t('pages.rules.rule-9-b') }}</a
          >
        </li>
      </ol>
      <p>{{ $t('pages.rules.text-3') }}</p>

      <a
        :href="locale === 'fr' ? HOW_TO_INSTALL_LINK_FR : HOW_TO_INSTALL_LINK_EN"
        aria-label="How to install"
        class="install"
        target="_blank"
        >{{ $t('pages.rules.how-to-install') }}
        <i class="fi fi-rr-arrow-up-right-from-square"></i>
      </a>

      <router-link :to="{ name: 'disclaimer' }" aria-label="Disclaimer">{{
        $t('pages.rules.disclaimer')
      }}</router-link>

      <button
        type="button"
        class="secondary"
        v-show="!isSignedIn"
        v-if="!inModal"
        @click="router.push({ name: 'auth' })"
      >
        {{ $t('pages.auth.menu.sign-up') }}
      </button>
      <button
        type="button"
        class="secondary bold"
        v-show="isSignedIn"
        v-if="!inModal"
        @click="router.push({ name: 'games' })"
      >
        {{ $t('pages.home.play-game') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { HOW_TO_INSTALL_LINK_FR, HOW_TO_INSTALL_LINK_EN } from '@/types/Constants'

const { locale } = useI18n()

const router = useRouter()
const isSignedIn = ref(false)

defineProps({
  inModal: {
    type: Boolean,
    required: false,
    default: false
  }
})

const validateUserIsSignedIn = () => {
  const auth = getAuth()
  onAuthStateChanged(auth, (user) => {
    isSignedIn.value = !!user
  })
}

onMounted(() => {
  validateUserIsSignedIn()
})
</script>

<style scoped lang="scss">
@import './Rules.scss';
</style>
