<template>
  <div ref="sideMenu">
    <transition :name="leftMenu ? 'slider-left' : 'slider'">
      <div class="settings-slider" :class="{ 'left-menu': leftMenu }" v-show="isOpen">
        <i
          v-show="isClosable"
          class="fi fi-br-cross"
          :class="{ 'left-cross': leftMenu, 'right-cross': !leftMenu }"
          @click.stop="close"
        ></i>
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  isClosable: {
    type: Boolean,
    default: true
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  leftMenu: {
    type: Boolean,
    default: false
  }
})

const sideMenu = ref<HTMLElement | undefined>()
const emit = defineEmits(['close'])

function checkClickPosition(e: MouseEvent) {
  if (e.target === null) return
  if (props.isOpen && sideMenu.value && !sideMenu.value.contains(e.target as Node)) {
    close()
  }
}

function close() {
  emit('close')
}

onMounted(() => {
  document.addEventListener('mousedown', checkClickPosition)
})

onUnmounted(() => {
  document.removeEventListener('mousedown', checkClickPosition)
})
</script>

<style scoped lang="scss">
@import './SideMenu.scss';
</style>
