<template>
  <div class="banner-container">
    <p>{{ $t('cookies.banner.text-1') }}</p>
    <p>{{ $t('cookies.banner.text-2') }}</p>
    <div class="buttons">
      <button class="primary bold full-width" @click="refuseAll">{{ $t('global.refuse') }}</button>
      <button class="secondary bold full-width" @click="acceptAll">
        {{ $t('global.accept') }}
      </button>
      <button class="tertiary bold full-width" @click="cookieModalOpen = true">
        {{ $t('cookies.banner.manage-preferences') }}
      </button>
    </div>
  </div>
  <Modal :fullScreen="true" :isOpen="cookieModalOpen" @close="closeCookieModal">
    <div class="modal-content">
      <div class="top">
        <h2 class="primary">{{ $t('cookies.modal.title') }}</h2>
        <p>{{ $t('cookies.modal.text') }}</p>
        <button class="secondary bold" @click="acceptAll">
          {{ $t('cookies.modal.accept-all') }}
        </button>
      </div>
      <div class="choices">
        <p class="control">{{ $t('cookies.modal.control') }}</p>
        <div class="switch-container">
          <Switch id="essentials" :modelValue="true" :disabled="true" />
          <p>
            <strong>{{ $t('cookies.modal.essentials.title') }}</strong>
            {{ $t('cookies.modal.essentials.text') }}
          </p>
        </div>
        <div class="switch-container">
          <Switch id="analythics" :modelValue="analythics" @update:modelValue="updateAnalythics" />
          <p>
            <strong>{{ $t('cookies.modal.analytics.title') }}</strong>
            {{ $t('cookies.modal.analytics.text') }}
          </p>
        </div>
        <div class="switch-container">
          <Switch id="ads" :modelValue="ads" @update:modelValue="updateAds" />
          <p>
            <strong>{{ $t('cookies.modal.ads.title') }}</strong>
            {{ $t('cookies.modal.ads.text') }}
          </p>
        </div>
      </div>
      <div class="buttons">
        <button class="primary bold" @click="closeCookieModal">
          {{ $t('global.refuse') }}
        </button>
        <button class="tertiary bold" @click="closeCookieModal">
          {{ $t('cookies.modal.save') }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/common/Global/modal/Modal.vue'
import { ref } from 'vue'
import Switch from '@/components/common/form/switch/Switch.vue'
import type { AcceptedCookies } from '@/types/Types'

const cookieModalOpen = ref(false)
const analythics = ref(false)
const ads = ref(false)
const acceptedCookies = ref<AcceptedCookies>({
  analythics: false,
  ads: false
})

const emit = defineEmits<{
  (event: 'close', acceptedCookies: AcceptedCookies): void
}>()

const closeCookieModal = () => {
  acceptedCookies.value = {
    analythics: analythics.value,
    ads: ads.value
  }

  emit('close', acceptedCookies.value)

  cookieModalOpen.value = false
}

const updateAnalythics = (value: boolean) => {
  analythics.value = value
}

const updateAds = (value: boolean) => {
  ads.value = value
}

const acceptAll = () => {
  analythics.value = true
  ads.value = true

  closeCookieModal()
}

const refuseAll = () => {
  analythics.value = false
  ads.value = false

  closeCookieModal()
}
</script>

<style scoped lang="scss">
@import './CookieBanner.scss';
</style>
