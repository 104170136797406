import { CategoryTypes, CategoryTypesEN } from './CategoryTypes'
import AvatarTypes from './AvatarTypes'

export type Category = {
  id?: string
  name: CategoryTypes | CategoryTypesEN
}

export const getCategoryTypeFromString = (
  category: string,
  language: string
): CategoryTypes | CategoryTypesEN => {
  switch (category) {
    case 'Jeu':
      return language === 'fr' ? CategoryTypes.GAME : CategoryTypesEN.GAME
    case 'Type de mouvement':
      return language === 'fr' ? CategoryTypes.MOVEMENT : CategoryTypesEN.MOVEMENT
    case 'Défis de force':
      return language === 'fr' ? CategoryTypes.PHYSICAL : CategoryTypesEN.PHYSICAL
    case "En dehors de l'escalade":
      return language === 'fr' ? CategoryTypes.OUTSIDE : CategoryTypesEN.OUTSIDE
    case 'Contrainte physique':
      return language === 'fr' ? CategoryTypes.CONSTRAINT : CategoryTypesEN.CONSTRAINT
    case 'En lien avec le parcours':
      return language === 'fr' ? CategoryTypes.PROBLEM : CategoryTypesEN.PROBLEM
    case 'En lien avec le temps':
      return language === 'fr' ? CategoryTypes.TIME : CategoryTypesEN.TIME
    default:
      return language === 'fr' ? CategoryTypes.OTHER : CategoryTypesEN.OTHER
  }
}

export const getCategoryImageFromStringAndAvatar = (
  category: string,
  avatar: AvatarTypes
): string => {
  switch (category) {
    case 'Jeu':
      return `/avatars/${avatar}/game/full.svg`
    case 'Type de mouvement':
      return `/avatars/${avatar}/movement-type/full.svg`
    case 'Défis de force':
      return `/avatars/${avatar}/physical/full.svg`
    case "En dehors de l'escalade":
      return `/avatars/${avatar}/outside-of-climbing/full.svg`
    case 'Contrainte physique':
      return `/avatars/${avatar}/physical-constraint/full.svg`
    case 'En lien avec le parcours':
      return `/avatars/${avatar}/problem-linked/full.svg`
    case 'En lien avec le temps':
      return `/avatars/${avatar}/time-linked/full.svg`
    default:
      return `/avatars/${avatar}.svg`
  }
}
