<template>
  <header ref="headerRef">
    <nav aria-label="Main Navigation">
      <div class="desktop-nav">
        <BrandLogo />
        <ul class="navigation" v-show="!isMobile">
          <NavItem :to="{ name: 'home' }" :label="$t('nav.home')" />
          <NavItem :to="{ name: 'what' }" :label="$t('nav.what')" />
          <NavItem :to="{ name: 'rules' }" :label="$t('nav.rules')" />
          <NavItem :to="{ name: 'store' }" :label="$t('nav.store')" />
          <NavItem :to="{ name: 'about' }" :label="$t('nav.about')" />
          <NavItem :to="{ name: 'contact' }" :label="$t('nav.contact')" />
        </ul>
        <div class="buttons" v-show="!isMobile">
          <router-link
            :to="{ name: 'auth' }"
            class="sign-in no-active mr-1"
            aria-label="Sign In"
            v-if="!authStore.isAuthenticated"
            ><button type="button" class="secondary">
              {{ $t('nav.connection') }}
            </button></router-link
          >
          <button
            class="no-style-button"
            @click="toggleRightMenu"
            v-if="authStore.isAuthenticated"
            aria-label="User Menu"
          >
            <img
              class="user-image"
              :src="
                user?.photoURL
                  ? user?.photoURL
                  : currentUser?.photoURL
                  ? currentUser.photoURL
                  : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
              "
              alt="User image"
              ref="userRef"
            />
          </button>
        </div>
      </div>
      <div ref="icon" class="icon" v-show="isMobile">
        <div
          class="hamburger-lines"
          @click="openMobileNav()"
          :class="{ 'icon-active': mobileMenu }"
          aria-label="Toggle Mobile Menu"
        >
          <span class="line1"></span>
          <span class="line2"></span>
          <span class="line3"></span>
        </div>
      </div>
      <SideMenu
        :isOpen="mobileMenu"
        :isClosable="false"
        :leftMenu="true"
        @close="toggleMobileNav"
        class="slider"
        aria-label="Mobile Menu"
      >
        <div class="settings-menu">
          <ul>
            <NavItem :to="{ name: 'home' }" :label="$t('nav.home')" @click="toggleMobileNav" />
            <NavItem :to="{ name: 'what' }" :label="$t('nav.what')" @click="toggleMobileNav" />
            <NavItem :to="{ name: 'rules' }" :label="$t('nav.rules')" @click="toggleMobileNav" />
            <NavItem :to="{ name: 'store' }" :label="$t('nav.store')" @click="toggleMobileNav" />
            <NavItem :to="{ name: 'about' }" :label="$t('nav.about')" @click="toggleMobileNav" />
            <NavItem
              :to="{ name: 'contact' }"
              :label="$t('nav.contact')"
              @click="toggleMobileNav"
            />
          </ul>
          <ul class="mobile-menu" v-if="authStore.isAuthenticated">
            <NavItem
              :to="{ name: 'parameters' }"
              :label="$t('nav.menu.settings')"
              @click="toggleMobileNav"
            />
          </ul>
        </div>
        <div class="footer">
          <router-link
            :to="{ name: 'auth' }"
            class="no-active w-full"
            @click="toggleMobileNav"
            aria-label="Sign In"
            ><button class="secondary ml-0 full-width" v-if="!authStore.isAuthenticated">
              {{ $t('nav.connection') }}
            </button></router-link
          >
          <button
            type="button"
            v-show="authStore.isAuthenticated"
            class="secondary full-width"
            @click="startGame"
          >
            {{ $t('nav.menu.start-game') }}
          </button>
          <button
            type="button"
            v-show="authStore.isAuthenticated"
            @click="handleSignOut"
            class="primary full-width"
          >
            {{ $t('nav.logout') }}
          </button>
        </div>
      </SideMenu>
    </nav>
    <SideMenu
      :isOpen="settingsOpen"
      @close="toggleRightMenu"
      class="slider"
      aria-label="Settings Menu"
    >
      <div class="settings-menu">
        <ul>
          <li>
            <router-link :to="{ name: 'parameters' }" aria-label="Settings" @click="close">{{
              $t('nav.menu.settings')
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="footer">
        <button
          type="button"
          v-show="authStore.firebaseUser"
          class="secondary full-width"
          @click="startGame"
          aria-label="Start Game"
        >
          {{ $t('nav.menu.start-game') }}
        </button>
        <button
          type="button"
          v-show="authStore.firebaseUser"
          @click="handleSignOut"
          class="primary full-width"
          aria-label="Logout"
        >
          {{ $t('nav.logout') }}
        </button>
      </div>
    </SideMenu>
  </header>
</template>

<script setup lang="ts">
import SideMenu from '@/components/common/Global/side-menu/SideMenu.vue'
import BrandLogo from '@/components/common/navbar/BrandLogo.vue'
import NavItem from '@/components/common/navbar/NavItem.vue'

import type { User } from '@/types/Types'

import { onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { getAuth, signOut } from 'firebase/auth'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/authStore'

const authStore = useAuthStore()
const router = useRouter()
const toast = useToast()
const { t, locale } = useI18n()

const isMobile = ref(false)
const mobileMenu = ref(false)
const settingsOpen = ref(false)
const currentUser = ref()
const headerRef = ref<HTMLElement | undefined>()
const user = ref<User | null>()
const doubleClick = ref(false)

function toggleMobileNav() {
  mobileMenu.value = !mobileMenu.value
  setTimeout(() => {
    doubleClick.value = false
  }, 500)
}

function openMobileNav() {
  if (!doubleClick.value) {
    doubleClick.value = true
    mobileMenu.value = true
    return
  }
  doubleClick.value = false
}

function checkScreenSize() {
  if (window.innerWidth < 960) {
    isMobile.value = true
    settingsOpen.value = false
    return
  }
  isMobile.value = false
  return
}

function toggleRightMenu() {
  settingsOpen.value = !settingsOpen.value
}

function handleSignOut() {
  signOut(getAuth())
    .then(() => {
      toast.success(t('nav.messages.logout-success'))
      router.push({ name: 'home' })
      settingsOpen.value = false
      mobileMenu.value = false
      authStore.firebaseUser = null
    })
    .catch((error) => {
      toast.error(t('nav.messages.logout-error'))
    })
}

async function validateUserIsSignedIn() {
  if (authStore.firebaseUser) {
    currentUser.value = authStore.firebaseUser
    user.value = authStore.dbUser
  }
}

function startGame() {
  if (headerRef.value) {
    headerRef.value.classList.add('slide-up')
    settingsOpen.value = false
    mobileMenu.value = false
    setTimeout(() => {
      router.push({ name: 'main-menu' })
    }, 300)
  }
}

function close() {
  settingsOpen.value = false
}

function getLocale() {
  if (user.value?.preferredLanguage) {
    locale.value = user.value.preferredLanguage
    localStorage.setItem('locale', locale.value)
  } else {
    const localLocale = localStorage.getItem('locale')
    if (localLocale) {
      locale.value = localLocale
    }
  }
}

onMounted(async () => {
  checkScreenSize()
  await validateUserIsSignedIn()
  getLocale()
  window.addEventListener('resize', checkScreenSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<style scoped lang="scss">
@import './Navbar.scss';
</style>
