<script setup lang="ts">
import { RouterView } from 'vue-router'
import Navbar from '@/components/common/navbar/Navbar.vue'
import Footer from '@/components/common/footer/Footer.vue'
import AdminNav from '@/components/admin/admin-nav/AdminNav.vue'
import GameNavbar from '@/components/common/Global/game-navbar/GameNavbar.vue'
import CookieBanner from '@/components/common/Global/cookies/CookieBanner.vue'
import CookieButton from '@/components/common/Global/cookies/CookieButton.vue'
import { applyCookies, getAcceptedCookies, applyCookiesToGtag } from '@/stores/cookies'
import type { AcceptedCookies } from '@/types/Types'
import { ref, onMounted } from 'vue'

const cookiesNotAknowledged = ref(false)
const displayCookies = ref(false)

const applyCookiePreferences = (acceptedCookies: AcceptedCookies) => {
  applyCookies(acceptedCookies)
  applyCookiesToGtag(acceptedCookies)
  cookiesNotAknowledged.value = false
  displayCookies.value = false
}

onMounted(() => {
  cookiesNotAknowledged.value = !getAcceptedCookies()
})
</script>

<template>
  <Suspense>
    <main>
      <Navbar v-if="!$route.path.includes('/admin') && !$route.path.includes('/game')" />
      <GameNavbar v-if="$route.path.includes('/game')" />
      <div class="container">
        <AdminNav v-if="$route.path.includes('/admin')" />
        <Transition name="main-transition">
          <RouterView v-slot="{ Component }" :key="$route.fullPath">
            <component :is="Component"></component>
          </RouterView>
        </Transition>
        <Footer v-if="!$route.path.includes('/admin') && !$route.path.includes('/game')" />
        <CookieButton v-if="!$route.path.includes('/game')" @click="displayCookies = true" />
        <CookieBanner
          @close="applyCookiePreferences"
          v-if="cookiesNotAknowledged || displayCookies"
        />
      </div>
    </main>
  </Suspense>
</template>

<style lang="scss">
@import './style/global.style.scss';
@import url('https://cdn-uicons.flaticon.com/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-solid-straight/css/uicons-solid-straight.css');
@import url('https://cdn-uicons.flaticon.com/uicons-thin-straight/css/uicons-thin-straight.css');
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-brands/css/uicons-brands.css');

main {
  background-image: url('/shapes/logo-yellow-grid.svg');
  background-repeat: repeat;
  background-position: center top;

  @include breakpoint-md {
    div {
      display: block;
    }
  }
}

.main-transition-enter-active,
.main-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.main-transition-enter-from,
.main-transition-leave-to {
  opacity: 0;
}
</style>
