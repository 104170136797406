<template>
  <div @click="handleClickOusideUl" class="field-container">
    <label v-if="title" class="title" :for="id"
      >{{ title }}<span v-if="!validation.required"> ({{ $t('global.optional') }})</span></label
    >
    <div class="input-group" :class="classes" ref="inputRef">
      <input
        :id="id"
        :name="title"
        :placeholder="placeholder"
        :value="selectedItem?.label || placeholder || 'Veuillez faire une sélection'"
        type="text"
        readonly
        @click="toggleList"
      />
      <i
        class="fi fi-ts-angle-small-down"
        :class="{ inverted: showList, upright: !showList }"
        @click="toggleList"
      ></i>
    </div>
    <ul v-show="showList" ref="ulRef">
      <li v-for="item in items" :key="item.id" @click="updateValue(item.id)">
        <input
          :id="`item-${item.id}`"
          :name="title"
          type="radio"
          :value="item.value"
          :checked="item.checked"
          @change="updateValue(item.id)"
        />
        <label :for="`item-${item.id}`" @change="updateValue(item.id)">{{ item.label }}</label>
      </li>
    </ul>
    <p v-if="!status.valid && touched">
      {{ status.message }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type { Status, ValidationParams, Item } from '@/types/Types'
import { validateOneSelected } from '@/Validation/validation'

import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: false,
    default: undefined
  },
  placeholder: {
    type: String,
    required: false,
    default: undefined
  },
  validation: {
    type: Object as () => ValidationParams,
    required: false,
    default: {
      required: false,
      minLength: undefined,
      maxLength: undefined,
      pattern: undefined
    }
  },
  touched: {
    type: Boolean,
    required: true,
    default: false
  },
  items: {
    type: Array as () => Item[],
    required: true
  }
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: Item[], status: Status): void
}>()

const selectedItem = computed(() => {
  if (props.items === undefined) return undefined
  return props.items.find((item) => item.checked)
})

function updateValue(id: string) {
  if (!id) return
  if (props.items === undefined) return

  uncheckItems()

  const item = props.items.find((item: Item) => item.id === id)
  if (!item) return

  item.checked = !item.checked
  props.items.splice(props.items.indexOf(item), 1, item)

  showList.value = false

  emit('update:modelValue', props.items, status.value)
  validateValue(props.items)
}

function uncheckItems() {
  if (props.items === undefined) return
  props.items.forEach((item: Item) => (item.checked = false))
}

const status = ref({ valid: true, message: '', touched: false } as Status)
function validateValue(value: Item[]) {
  if (props.validation.required) {
    const checked = value.filter((item: Item) => item.checked)
    status.value = validateOneSelected(checked, t)
  } else status.value = { valid: true, message: '', touched: false }
}

const classes = computed(() => ({
  'border-danger': !status.value.valid && status.value.touched,
  'border-success': status.value.valid && status.value.touched,
  placeholder: selectedItem.value === undefined
}))

let ulRef = ref<HTMLElement | undefined>()
let inputRef = ref<HTMLElement | undefined>()
function handleClickOusideUl(event: MouseEvent) {
  if (
    ulRef.value &&
    !ulRef.value.contains(event.target as Node) &&
    inputRef.value &&
    !inputRef.value.contains(event.target as Node)
  ) {
    showList.value = false
  }
}

let showList = ref(false)
function toggleList() {
  showList.value = !showList.value
}

onMounted(() => {
  document.addEventListener('click', handleClickOusideUl)
})
</script>

<style lang="scss" scoped>
@import './Select.scss';
</style>
