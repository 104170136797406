import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  getDoc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore'

import { type Router } from 'vue-router'
import { db } from '@/main'
import type { Game } from '@/types/Types'

export function getGame(gameID: string, router: Router): Promise<Game> {
  return new Promise((resolve) => {
    onSnapshot(doc(db, 'games', gameID), (doc) => {
      if (!doc.exists() || doc.data() === undefined) {
        router.push({ name: 'not-found-game' })
        return
      }
      const data = doc.data()
      const fetchedGame: Game = {
        id: doc.id,
        challenges: data.challenges,
        packs: data.packs || [],
        currentChallengeIndex: data.currentChallengeIndex,
        gameStartedDate: data.gameStartedDate,
        owner: data.owner,
        players: data.players
      }

      resolve(fetchedGame)
    })
  })
}

export async function createGame(game: Game) {
  const gameRef = await addDoc(collection(db, 'games'), game)
  return gameRef.id
}

export async function updateGame(game: Game) {
  if (!game?.id) return

  const gameRef = doc(db, 'games', game.id)

  await updateDoc(gameRef, {
    currentChallengeIndex: game.currentChallengeIndex,
    players: game.players
  })
}

export async function removeGameFromOwner(game: Game) {
  const userRef = doc(db, 'users', game.owner)
  const userData = (await getDoc(userRef)).data()

  await updateDoc(userRef, {
    pastGame: game,
    currentGameId: '',
    gamesPlayed: userData?.gamesPlayed + 1 || 1
  })
}

export async function deleteGame(gameId: string) {
  const gameRef = doc(db, 'games', gameId)
  await deleteDoc(gameRef)
}
