enum CategoryTypes {
  GAME = 'Jeu',
  MOVEMENT = 'Type de mouvement',
  PHYSICAL = 'Défis de force',
  OUTSIDE = "En dehors de l'escalade",
  CONSTRAINT = 'Contrainte physique',
  PROBLEM = 'En lien avec le parcours',
  TIME = 'En lien avec le temps',
  OTHER = 'Autre'
}

enum CategoryTypesEN {
  GAME = 'Game',
  MOVEMENT = 'Movement type',
  PHYSICAL = 'Physical challenges',
  OUTSIDE = 'Outside of climbing',
  CONSTRAINT = 'Physical constraint',
  PROBLEM = 'Problem linked',
  TIME = 'Time linked',
  OTHER = 'Other'
}

export { CategoryTypes, CategoryTypesEN }
