export default [
  {
    path: '/auth',
    children: [
      {
        path: '',
        name: 'auth',
        component: () => import('@/views/Auth/Auth.Menu.vue')
      },
      {
        path: 'sign-in',
        name: 'sign-in',
        component: () => import('@/views/Auth/Auth.SignIn.vue')
      },
      {
        path: 'sign-up',
        name: 'sign-up',
        component: () => import('@/views/Auth/Auth.SignUp.vue')
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/Auth/Auth.ForgotPassword.vue')
      }
    ]
  }
]
