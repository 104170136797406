enum AvatarTypes {
  GREEN_YELLOW = '01-green-yellow',
  BLUE_RED = '02-blue-red',
  PURPLE_YELLOW = '03-purple-yellow',
  BLUE_YELLOW = '04-blue-yellow',
  RED_BLUE = '05-red-blue',
  ORANGE_GREEN = '06-orange-green',
  DARK_BLUE_YELLOW = '07-dark-blue-yellow',
  GREEN_PINK = '08-green-pink'
}

export default AvatarTypes
