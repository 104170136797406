import { addDoc, getDocs, collection } from 'firebase/firestore'
import { db } from '@/main'
import type { Game, Challenge, Feedback, FeedbackMessage } from '@/types/Types'

interface MailService {
  sendContactEmail: (
    subject: string,
    name: string,
    email: string,
    message: string,
    toast: any,
    t: (key: string) => string
  ) => void
  sendFeedback: (
    subject: string,
    message: string,
    game: Game | undefined,
    challenge: Challenge | undefined,
    toast: any,
    t: (key: string) => string
  ) => void
  getAllFeedbacks: () => Promise<Feedback[]>
}

const mailService: MailService = {
  sendContactEmail: (subject, name, email, message, toast, t) => {
    const contactRef = collection(db, 'contact')
    addDoc(contactRef, {
      to: ['info@allez-allez.app'],
      from: `${name} <${email}>`,
      message: {
        subject: `Contact - ${subject}`,
        text: message,
        html: `<h1>${subject}</h1><p>${message}</p>`
      },
      category: 'contact'
    })
      .then(() => {
        toast.success(t('pages.contact.messages.success'))
      })
      .catch((error) => {
        console.error(error.code, error.message)
        toast.error(t('pages.contact.messages.error'))
      })
  },

  sendFeedback: (subject, message, game, challenge, toast, t) => {
    const contactRef = collection(db, 'contact')
    addDoc(contactRef, {
      to: ['info@allez-allez.app'],
      from: `Feedback`,
      message: {
        subject: `Feedback - ${subject}`,
        text: message,
        html: `<h1>${subject}</h1><p>${message}</p>`
      },
      category: 'feedback',
      game: game ?? '',
      challenge: challenge ?? ''
    })
      .then(() => {
        toast.success(t('game.game.feedback.messages.success'))
      })
      .catch((error) => {
        console.error(error.code, error.message)
        toast.error(t('game.game.feedback.messages.error'))
      })
  },

  getAllFeedbacks: async () => {
    const feedbacks: Feedback[] = []
    const feedbackRef = collection(db, 'contact')
    const feedbacksSnapshot = await getDocs(feedbackRef)
    feedbacksSnapshot.forEach((doc) => {
      const feedback = {
        id: doc.id,
        feedbackCategory: doc.data().category,
        game: doc.data().game as Game,
        challenge: doc.data().challenge as Challenge,
        message: doc.data().message as FeedbackMessage
      }

      feedbacks.push(feedback)
    })
    return feedbacks
  }
}

export default mailService
