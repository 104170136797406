<template>
  <div>
    <label v-if="title !== undefined" :for="title" class="primary"
      >{{ title }}<span v-if="!validation.required"> ({{ $t('global.optional') }})</span></label
    >
    <textarea
      :id="id"
      :name="title"
      :placeholder="placeholder"
      :value="value"
      :class="classes"
      :rows="rows"
      @input="updateValue"
    />
    <p v-if="!status.valid">
      {{ status.message }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type { Status, ValidationParams } from '@/types/Types'
import { validate, required, length, regex } from '@/Validation/validation'

import { computed, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: false,
    default: undefined
  },
  placeholder: {
    type: String,
    required: false,
    default: undefined
  },
  value: {
    type: String,
    required: false,
    default: ''
  },
  rows: {
    type: Number,
    required: false,
    default: 4
  },
  validation: {
    type: Object as () => ValidationParams,
    required: false,
    default: {
      required: false,
      length: null,
      regex: null
    }
  }
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: string, status: Status): void
}>()

function updateValue(e: Event) {
  const value = (e.target as HTMLInputElement).value
  validateInput(value)
  emit('update:modelValue', value, status.value)
}

const classes = computed(() => {
  return {
    'border-danger': !status.value.valid && status.value.touched,
    'border-success': status.value.valid && status.value.touched
  }
})

const status = ref({ valid: true, message: '', touched: false } as Status)

function validateInput(value: string) {
  const rules = []
  if (props.validation.required) rules.push(required)
  if (props.validation.length) rules.push(length(props.validation.length, t))
  if (props.validation.regex)
    rules.push(
      regex({ regex: props.validation.regex.value, message: props.validation.regex.message }, t)
    )
  status.value = validate(value, rules, t)
}

const inputValue = ref(props.value)

watchEffect(() => {
  inputValue.value = props.value
})

const isFirstRender = ref(true)

watchEffect(() => {
  if (isFirstRender.value) {
    isFirstRender.value = false
    return
  }
  validateInput(inputValue.value)
})
</script>

<style scoped lang="scss">
@import './TextArea.scss';
</style>
