<template>
  <dialog ref="modal" :class="[fullScreen ? 'full-screen' : shape, { blurred: blurred }]">
    <i v-if="isClosable" class="fi fi-br-cross" @click="close"></i>
    <slot />
  </dialog>
</template>

<script setup lang="ts">
import { SHAPES } from '@/components/common/Global/modal/Shapes'
import { ref, onMounted, watch } from 'vue'

const props = defineProps({
  isClosable: {
    type: Boolean,
    default: true
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  isOutsideClosable: {
    type: Boolean,
    default: true
  },
  shape: {
    type: String,
    default: SHAPES.CHOPPED
  },
  blurred: {
    type: Boolean,
    default: true
  },
  fullScreen: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close'])

const modal = ref<HTMLDialogElement | null>(null)

onMounted(() => {
  if (props.isOpen) {
    modal.value?.showModal()
  }

  modal.value?.addEventListener('click', (e) => {
    const dialogDimentions = modal.value?.getBoundingClientRect()
    if (dialogDimentions === undefined) return
    if (
      (e.clientX < dialogDimentions.left ||
        e.clientX > dialogDimentions.right ||
        e.clientY < dialogDimentions.top ||
        e.clientY > dialogDimentions.bottom) &&
      props.isOutsideClosable
    ) {
      modal.value?.close()
      emit('close')
    }
  })
})

watch(
  () => props.isOpen,
  (newValue) => {
    if (newValue) {
      modal.value?.showModal()
    } else {
      modal.value?.close()
    }
  }
)

const close = () => {
  modal.value?.close()
  emit('close')
}
</script>

<style lang="scss" scoped>
@import './Modal.scss';
</style>
