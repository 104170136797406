import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'

import authRoutes from './authRoutes'
import adminRoutes from './adminRoutes'
import websiteRoutes from './websiteRoutes'
import gameRoutes from './gameRoutes'
import errorRoutes from './errorRoutes'

const routes = [...authRoutes, ...adminRoutes, ...websiteRoutes, ...gameRoutes, ...errorRoutes]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 95,
        behavior: 'smooth'
      }
    }
  },
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  await authStore.getSignedInUser(authStore)

  if (!authStore.firebaseUser) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      console.log('Route requires authentication, redirecting to sign-in')
      next({ name: 'sign-in' })
      return
    }
    next()
    return
  }

  const idTokenResult = await authStore.firebaseUser.getIdTokenResult()

  if (
    ['auth', 'sign-in', 'sign-up', 'forgot-password'].includes(to.name as string) &&
    authStore.firebaseUser
  ) {
    next({ name: 'home' })
    return
  }

  if (to.matched.some((record) => record.meta.requiresAdmin) && authStore.firebaseUser) {
    if (!idTokenResult.claims.admin) {
      next({ name: 'forbidden' })
      return
    }
  }

  if (to.name === 'admin' && authStore.firebaseUser) {
    next('/admin/packs')
    return
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !authStore.firebaseUser) {
    next({ name: 'sign-in' })
    return
  }

  if (to.matched.some((record) => record.meta.requiresAcceptance) && authStore.firebaseUser) {
    const userInfo = authStore.dbUser

    if (!userInfo) {
      next({ name: 'home' })
      return
    }

    if (!userInfo?.disclaimerAccepted) {
      next({ name: 'disclaimer-game' })
      return
    }
  }

  next()
})

export default router
